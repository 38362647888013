/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input } from '@windmill/react-ui';

interface RadioButtonRowProps {
  label: string;
  checked: boolean | null;
  onChange: (value: boolean | null) => void;
  disabled: boolean;
  checkedOptionLabel: string,
  uncheckedOptionLabel: string,
  hasNAOption?: boolean;
  naOptionLabel?: string;
}

export default function RadioButtonRow({ label, checked, onChange, disabled, checkedOptionLabel, uncheckedOptionLabel, hasNAOption = undefined, naOptionLabel = undefined }: RadioButtonRowProps) {
  const yesButtonId = `signing-form-radio-button-yes-${label}`;
  const noButtonId = `signing-form-radio-button-no-${label}`;
  const naButtonId = `signing-form-radio-button-na-${label}`;

  const isNull = checked === null;

  return (
    <div className="flex justify-between items-center">
      <div style={{ flexGrow: 1 }}>
        {label}
      </div>
      <div className="radio-buttons whitespace-nowrap">
        <label htmlFor={yesButtonId} className="mr-2">
          {checkedOptionLabel}
        </label>
        <Input
          type="radio"
          name={label}
          checked={!isNull && checked}
          css=""
          id={yesButtonId}
          onChange={e => onChange(e.currentTarget.checked)}
          className="mr-8"
          required
          disabled={disabled}
        />
        <label htmlFor={noButtonId} className="mr-2">
          {uncheckedOptionLabel}
        </label>
        <Input
          type="radio"
          name={label}
          checked={!isNull && !checked}
          css=""
          id={noButtonId}
          onChange={e => onChange(!e.currentTarget.checked)}
          className={hasNAOption ? 'mr-8' : ''}
          required
          disabled={disabled}
        />
        {(hasNAOption && (
          <>
            <label htmlFor={naButtonId} className="mr-2">
              {naOptionLabel}
            </label>
            <Input
              type="radio"
              name={label}
              checked={isNull}
              css=""
              id={naButtonId}
              onChange={e => onChange(null)}
              required
              disabled={disabled}
            />
          </>
        ))}
      </div>
    </div>
  );
}
