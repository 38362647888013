import { User } from 'src/models/user';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export async function fetchUserById(id: number) {
  try {
    const res = await api.get(`api/users/${id}`);
    return res.data.data as User;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchUserByIdClassId(id: number, classId: number) {
  try {
    const res = await api.get(`api/users/${id}/classes/${classId}`);
    return res.data.data as User;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchMyUser() {
  try {
    const res = await api.get('api/users/me');
    return res.data.data as User;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
