import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

interface FetchGuidancePdfParameters {
  formId: number;
}

interface FetchFormsPdfParameters {
  classId: number;
  studentUserId: number;
  formId: number;
}

interface FetchFormsPdfFullParameters {
  classId: number;
  studentUserId: number;
}

export async function fetchFormsPdf({ classId, studentUserId, formId }: FetchFormsPdfParameters) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/forms/${formId}/pdf`, { responseType: 'blob' });
    return new Blob([res.data], { type: 'application/pdf' });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchGuidancePdfFull({ formId }: FetchGuidancePdfParameters) {
  try {
    const res = await api.get(`api/forms/${formId}/pdf`, { responseType: 'blob' });
    return new Blob([res.data], { type: 'application/pdf' });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchFormsPdfFull({ classId, studentUserId }: FetchFormsPdfFullParameters) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/full/pdf`, { responseType: 'blob' });
    return new Blob([res.data], { type: 'application/pdf' });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchSigningFormPdf({ classId, studentUserId }: FetchFormsPdfFullParameters) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/signingform/pdf`, { responseType: 'blob' });
    return new Blob([res.data], { type: 'application/pdf' });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchWarningPdf({ classId, studentUserId }: FetchFormsPdfFullParameters) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/warning/pdf`, { responseType: 'blob' });
    return new Blob([res.data], { type: 'application/pdf' });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
