import DateOnly from 'src/models/common/DateOnly';
import SigningForm from 'src/models/SigningForm';
import SigningFormValidation from 'src/models/SigningFormValidation';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export async function fetchSigningFormByClassAndStudent(classId: number, studentUserId: number) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/signingform`);
    const form = res.data.data as SigningForm;

    if (form.midSemesterCompletedDate) form.midSemesterCompletedDate = new DateOnly(form.midSemesterCompletedDate);
    if (form.endSemesterCompletedDate) form.endSemesterCompletedDate = new DateOnly(form.endSemesterCompletedDate);
    if (form.signedByTeacherDate) form.signedByTeacherDate = new Date(form.signedByTeacherDate);
    if (form.signedByStudentDate) form.signedByStudentDate = new Date(form.signedByStudentDate);
    if (form.signedByAdvisorDate) form.signedByAdvisorDate = new Date(form.signedByAdvisorDate);

    return form;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchSigningFormValidationByClassAndStudent(classId: number, studentUserId: number) {
  try {
    const res = await api.get(`api/classes/${classId}/students/${studentUserId}/signingform/validation`);
    const validation = res.data.data as SigningFormValidation;

    return validation;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function createSigningForm(classId: number, studentUserId: number, signingForm: SigningForm) {
  try {
    const requestData = {
      data: signingForm,
    };
    const res = await api.post(`api/classes/${classId}/students/${studentUserId}/signingform`, requestData);
    return res.data.data as SigningForm;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function updateSigningForm(classId: number, studentUserId: number, signingForm: SigningForm) {
  try {
    const requestData = {
      data: signingForm,
    };
    const res = await api.put(`api/classes/${classId}/students/${studentUserId}/signingform`, requestData);
    return res.data.data as SigningForm;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function signSigningForm(classId: number, studentUserId: number) {
  try {
    await api.post(`api/classes/${classId}/students/${studentUserId}/signingform/sign`);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function cancelSigningForm(classId: number, studentUserId: number) {
  try {
    await api.post(`api/classes/${classId}/students/${studentUserId}/signingform/cancel`);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
