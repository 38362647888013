import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import SigningFormValidation from 'src/models/SigningFormValidation';
import { QuestionType } from 'src/models/form';
import Button from '../_common/Button/Button';

interface ConfirmSignModalProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  onMissingSupervisorConfirmationChanged: (confirmed: boolean) => void;
  teacherWaitingForOtherSignatures: boolean;
  teacherWaitingForSupervisorSignature: boolean;
  signingWithoutSupervisorConfirmed: boolean;
  signingFormValidation: SigningFormValidation;
}

export default function ConfirmSignModal({ isOpen,
  close,
  confirm,
  teacherWaitingForOtherSignatures,
  teacherWaitingForSupervisorSignature,
  signingWithoutSupervisorConfirmed,
  onMissingSupervisorConfirmationChanged,
  signingFormValidation }: ConfirmSignModalProps) {
  const [continueWithoutSupervisor, setContinueWithoutSupervisor] = useState<boolean>(false);

  function onConfirmationChanged(confirmed: boolean) {
    onMissingSupervisorConfirmationChanged(confirmed);
    setContinueWithoutSupervisor(confirmed);
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalHeader>
        <FormattedMessage id="send-signing-form" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="cant-change-signing-form" />
        {signingFormValidation.teacherAnswerSummary && (<div className="mt-2"><FormattedMessage id="signingFormAnswerSummaryHeader" /></div>)}
        {signingFormValidation.teacherAnswerSummary
          && signingFormValidation.teacherAnswerSummary
            .filter(item => item.formQuestionType === QuestionType.Grading)
            .sort((a) => (a.isMidSemester ? -1 : 1))
            .map(item => (
              <div>
                <FormattedMessage id="signingFormAnswerSummary" values={{ semester: (item.isMidSemester ? <FormattedMessage id="mid-semester" /> : <FormattedMessage id="end-semester" />), totalAnswers: item.totalAnswers, totalQuestions: item.totalQuestions }} />
              </div>
            ))}
        {teacherWaitingForOtherSignatures && (
          <div className="font-bold">
            <br />
            <FormattedMessage id="signingFormOthersNotSigned" />
          </div>
        )}
        {teacherWaitingForSupervisorSignature && (
          <div className="font-bold">
            <br />
            <FormattedMessage id="signingFormSupervisorNotSigned" />
            <Input type="checkbox" className="ml-2" css="" defaultChecked={signingWithoutSupervisorConfirmed} value="supervisor-text" onChange={e => onConfirmationChanged(e.target.checked)} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} layout="outline">
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          onClick={() => {
            confirm();
            close();
          }}
          disabled={teacherWaitingForSupervisorSignature && !continueWithoutSupervisor}
        >
          <FormattedMessage id="sign" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
