import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormattedMessage } from 'react-intl';
import SigningFormValidation from 'src/models/SigningFormValidation';
import Button from '../_common/Button/Button';

interface ConfirmCancelModalProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  signingFormValidation: SigningFormValidation;
}

export default function ConfirmCancelModal({ isOpen,
  close,
  confirm,
  signingFormValidation }: ConfirmCancelModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalHeader>
        <FormattedMessage id="cancel-signing-form" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="permanent-cancel-signing-form" />
        {signingFormValidation.assessmentResultArchived && (<div className="font-bold mt-3"><FormattedMessage id="signingFormArchived" /></div>)}
        {signingFormValidation.assessmentResultSubmitted && (<div className="font-bold mt-3"><FormattedMessage id="signingFormSubmitted" /></div>)}
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} layout="outline">
          <FormattedMessage id="cancel" />
        </Button>
        <Button onClick={() => {
          confirm();
          close();
        }}
        >
          <FormattedMessage id="delete" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
